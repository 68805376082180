body {
  margin: 0;
  padding: 0;
  font-family: "Gotham", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: normal;
  src: url("/gotham-medium.otf") format("opentype"); }

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: bold;
  src: url("/gotham-bold.otf") format("opentype"); }

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 300;
  src: url("/gotham-book.otf") format("opentype"); }

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 100;
  src: url("/gotham-light.otf") format("opentype"); }

.container {
  max-width: 1000px !important;
  &.container-header {
    max-width: 800px !important; } }

a {
  &, &:hover {
    cursor: pointer;
    color: #154559;
    text-decoration: none; } }

h1 {
  font-size: 22px;
  letter-spacing: 9px; }
h2 {
  font-size: 20px;
  letter-spacing: 8px;
  margin-bottom: 26px; }
h5 {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 2px; }
p {
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: 300;
  &.lead {
    font-size: 22px;
    font-weight: 300;
    margin-top: 60px;
    letter-spacing: 2px; } }

@media (max-width: 790px) {
  .hidden-xs {
    display: none; }
  p.lead {
    font-size: 17px;
    margin-right: 10px;
    margin-left: 10px; } }

p.paragraph-responsive {
  margin-right: 10px;
  margin-left: 10px; }

.features {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  max-width: 360px;
  margin: auto;
  > div {
    margin-bottom: 20px; }
  @media (max-width: 840px) {
    flex-direction: column; } }

.team-member {
  margin-top: 20px;
  padding-right: 20px;
  h2 {
    margin-bottom: 12px; }
  h3 {
    margin-bottom: 6px;
    letter-spacing: 5px; }
  > div {
    display: flex;
    p {
      margin-bottom: 10px;
      text-align: justify; }
    .avatar-container {
      margin-right: 15px;
      text-align: right; }
    .avatar {
      width: 150px;
      @media (max-width: 839px) {
        max-width: 80px; }
      &.avatar-small {
        width: 110px; } } } }

.skills {
  margin-top: 40px;
  @media (min-width: 768px) {
    > div {
      &:first-of-type {
        padding-right: 30px;
        border-right: 1px solid #cbc9c9; }
      &:last-of-type {
        padding-left: 30px; } } } }

.circle {
  position: relative;
  top: -2px;
  width: 4px;
  height: 4px;
  background: #154559;
  display: inline-block;
  border-radius: 50%;
  margin: 0 2px; }

.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.04) 2px 0px 21px 1px;
  padding: 80px 50px 50px !important; }
