/*!
 * Bootstrap Reboot v4.2.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}
/*# sourceMappingURL=bootstrap-reboot.css.map */
body {
  margin: 0;
  padding: 0;
  font-family: "Gotham", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: normal;
  src: url("/gotham-medium.otf") format("opentype"); }

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: bold;
  src: url("/gotham-bold.otf") format("opentype"); }

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 300;
  src: url("/gotham-book.otf") format("opentype"); }

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 100;
  src: url("/gotham-light.otf") format("opentype"); }

.container {
  max-width: 1000px !important; }
  .container.container-header {
    max-width: 800px !important; }

a, a:hover {
  cursor: pointer;
  color: #154559;
  text-decoration: none; }

h1 {
  font-size: 22px;
  letter-spacing: 9px; }

h2 {
  font-size: 20px;
  letter-spacing: 8px;
  margin-bottom: 26px; }

h5 {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 2px; }

p {
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: 300; }
  p.lead {
    font-size: 22px;
    font-weight: 300;
    margin-top: 60px;
    letter-spacing: 2px; }

@media (max-width: 790px) {
  .hidden-xs {
    display: none; }
  p.lead {
    font-size: 17px;
    margin-right: 10px;
    margin-left: 10px; } }

p.paragraph-responsive {
  margin-right: 10px;
  margin-left: 10px; }

.features {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  max-width: 360px;
  margin: auto; }
  .features > div {
    margin-bottom: 20px; }
  @media (max-width: 840px) {
    .features {
      flex-direction: column; } }

.team-member {
  margin-top: 20px;
  padding-right: 20px; }
  .team-member h2 {
    margin-bottom: 12px; }
  .team-member h3 {
    margin-bottom: 6px;
    letter-spacing: 5px; }
  .team-member > div {
    display: flex; }
    .team-member > div p {
      margin-bottom: 10px;
      text-align: justify; }
    .team-member > div .avatar-container {
      margin-right: 15px;
      text-align: right; }
    .team-member > div .avatar {
      width: 150px; }
      @media (max-width: 839px) {
        .team-member > div .avatar {
          max-width: 80px; } }
      .team-member > div .avatar.avatar-small {
        width: 110px; }

.skills {
  margin-top: 40px; }
  @media (min-width: 768px) {
    .skills > div:first-of-type {
      padding-right: 30px;
      border-right: 1px solid #cbc9c9; }
    .skills > div:last-of-type {
      padding-left: 30px; } }

.circle {
  position: relative;
  top: -2px;
  width: 4px;
  height: 4px;
  background: #154559;
  display: inline-block;
  border-radius: 50%;
  margin: 0 2px; }

.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.04) 2px 0px 21px 1px;
  padding: 80px 50px 50px !important; }


